<template>
  <m-container>
    <v-card class="m-light-card">
      <v-tabs v-model="currentStep">
        <v-tab> 1. Essay </v-tab>
        <v-tab> 2. Questions & Answers </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentStep">
        <v-tab-item>
          <essay-form
            v-model="formData.essay"
            :course-id="this.$courseId()"
            :essay="essay"
            :topics="topics"
            :is-valid.sync="essayok"
          ></essay-form>
          <tab-navigation
            previous-disabled
            @next="nextStep"
            @previous="previousStep"
          ></tab-navigation>
        </v-tab-item>

        <v-tab-item>
          <question-set-form
            v-model="formData.questions"
            :questions="questions"
            :is-valid.sync="qaok"
          ></question-set-form>
          <div class="px-4">
            <submission-alert-message
              v-show="showAlertCondition"
              :message="badExamFormMessage[$language]"
            ></submission-alert-message>
          </div>
          <tab-navigation
            :next-disabled="true"
            @next="nextStep"
            @previous="previousStep"
          >
            <template v-slot:right>
              <m-rounded-button
                outlined
                color="red accent-3"
                data-qa="cancel-button"
                @click="cancel"
              >
                <m-icon left>cancel</m-icon>
                Cancel
              </m-rounded-button>
              <m-rounded-button
                :loading="creating"
                class="ml-2"
                @click="save"
              >
                <m-icon left>save_alt</m-icon>
                Save
              </m-rounded-button>
            </template>
          </tab-navigation>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </m-container>
</template>

<script>
import EssayForm from "#ecf/question-bank/components/GroupQuestion/FormEssay";
import QuestionSetForm from "#ecf/question-bank/components/GroupQuestion/FormQuestionSet";
import TabNavigation from "~ecf/components/TabNavigation";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";

export default {
  name: "GroupQuestionForm",
  components: {
    QuestionSetForm,
    EssayForm,
    TabNavigation,
    SubmissionAlertMessage
  },
  props: {
    essay: {
      type: Object,
      required: true
    },
    questions: {
      type: Array,
      required: true
    },
    isValid: {
      type: Boolean,
      default: true
    },
    submitting: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  inject: ["$courseOwnerId", "$courseId"],
  data() {
    return {
      difficulty: ["Easy", "Medium", "Hard"],
      formData: { essay: { ...this.essay }, questions: [...this.questions] },
      currentStep: 0,
      creating: false,
      essayok: true,
      qaok: true,
      showAlertCondition: false,
    };
  },
  computed: {
    topics() {
      return this.$store.state.questionBank.topics.topics;
    },
    badExamFormMessage() {
      return {
        false: "You must fill out all the mandatory fields",
        true: "সকল প্রয়োজনীয় তথ্য পূরণ করুন",
      };
    },
    allowSaveCondition(){
      return this.essayok && this.qaok
    }
  },
  watch: {
    formData: {
      deep: true,
      handler(value) {
        this.$emit("input", value);
      }
    },
    allowSaveCondition: {
      handler(value){
        if(value) this.showAlertCondition = false;
      }
    }
  },
  created() {
    this.$store.dispatch("questionBank/topics/list", {
      owner_id: this.$courseOwnerId(),
      course_id: this.$courseId()
    });
  },
  methods: {
    nextStep() {
      if (this.currentStep !== 1) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep !== 0) {
        this.currentStep--;
      }
    },
    async save() {
      try {
        /*this.creating = true;
        const essayId = await this.$store.dispatch("questionBank/createEssay", {
          essay: this.essay,
        });
        const questionPromises = this.questions.map(q => {
          if (q.questionDetails.text === "") q.questionDetails.text = undefined;
          q.essay_id = essayId;
          q.questionDetails.topics = this.essay.topics ?? [];
          return this.$store.dispatch("questionBank/create", { question: q });
        });
        await Promise.all(questionPromises);*/
        if(this.allowSaveCondition) this.$emit("saved");
        else this.showAlertCondition = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.creating = false;
      }
    },
    async cancel() {
      this.$emit("cancel");
    }
  }
};
</script>

<style></style>
