var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: { "previous-location": "", "page-title": "Edit Question Group" },
      }),
      _vm.essay
        ? _c("group-question-form", {
            attrs: { essay: _vm.essay, questions: _vm.essay.questions },
            on: { saved: _vm.onSave, cancel: _vm.onCancel },
            model: {
              value: _vm.essayData,
              callback: function ($$v) {
                _vm.essayData = $$v
              },
              expression: "essayData",
            },
          })
        : _c(
            "m-row",
            { staticClass: "mt-5", attrs: { justify: "center" } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "primary" },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }