var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _c(
        "v-card",
        { staticClass: "m-light-card" },
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.currentStep,
                callback: function ($$v) {
                  _vm.currentStep = $$v
                },
                expression: "currentStep",
              },
            },
            [
              _c("v-tab", [_vm._v(" 1. Essay ")]),
              _c("v-tab", [_vm._v(" 2. Questions & Answers ")]),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.currentStep,
                callback: function ($$v) {
                  _vm.currentStep = $$v
                },
                expression: "currentStep",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  _c("essay-form", {
                    attrs: {
                      "course-id": this.$courseId(),
                      essay: _vm.essay,
                      topics: _vm.topics,
                      "is-valid": _vm.essayok,
                    },
                    on: {
                      "update:isValid": function ($event) {
                        _vm.essayok = $event
                      },
                      "update:is-valid": function ($event) {
                        _vm.essayok = $event
                      },
                    },
                    model: {
                      value: _vm.formData.essay,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "essay", $$v)
                      },
                      expression: "formData.essay",
                    },
                  }),
                  _c("tab-navigation", {
                    attrs: { "previous-disabled": "" },
                    on: { next: _vm.nextStep, previous: _vm.previousStep },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c("question-set-form", {
                    attrs: { questions: _vm.questions, "is-valid": _vm.qaok },
                    on: {
                      "update:isValid": function ($event) {
                        _vm.qaok = $event
                      },
                      "update:is-valid": function ($event) {
                        _vm.qaok = $event
                      },
                    },
                    model: {
                      value: _vm.formData.questions,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "questions", $$v)
                      },
                      expression: "formData.questions",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "px-4" },
                    [
                      _c("submission-alert-message", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showAlertCondition,
                            expression: "showAlertCondition",
                          },
                        ],
                        attrs: {
                          message: _vm.badExamFormMessage[_vm.$language],
                        },
                      }),
                    ],
                    1
                  ),
                  _c("tab-navigation", {
                    attrs: { "next-disabled": true },
                    on: { next: _vm.nextStep, previous: _vm.previousStep },
                    scopedSlots: _vm._u([
                      {
                        key: "right",
                        fn: function () {
                          return [
                            _c(
                              "m-rounded-button",
                              {
                                attrs: {
                                  outlined: "",
                                  color: "red accent-3",
                                  "data-qa": "cancel-button",
                                },
                                on: { click: _vm.cancel },
                              },
                              [
                                _c("m-icon", { attrs: { left: "" } }, [
                                  _vm._v("cancel"),
                                ]),
                                _vm._v(" Cancel "),
                              ],
                              1
                            ),
                            _c(
                              "m-rounded-button",
                              {
                                staticClass: "ml-2",
                                attrs: { loading: _vm.creating },
                                on: { click: _vm.save },
                              },
                              [
                                _c("m-icon", { attrs: { left: "" } }, [
                                  _vm._v("save_alt"),
                                ]),
                                _vm._v(" Save "),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }