<template>
  <m-container>
    <page-title previous-location page-title="Edit Question Group" class="mt-5">
    </page-title>

    <!-- <lazy-load :item="essay">
      <m-container v-if="essay">
        <m-card class="pa-3">
          <m-card-text>
            <m-textarea
              v-model="essay.text"
              label="Essay"
              rows="5"
              auto-grow
            ></m-textarea>
            <div class="d-flex justify-center">
              <image-input
                v-model="essay.image"
                class="d-flex flex-column justify-center mt-10"
              >
                <template v-slot:activator>
                  <div class="d-flex justify-center">
                    <m-button outlined small color="primary"
                      >Upload Image</m-button
                    >
                  </div>
                </template>
                <template v-slot:placeholder>
                  <m-icon size="64">image</m-icon>
                </template>
              </image-input>
            </div>
          </m-card-text>
        </m-card>
      </m-container>

      <m-container>
        <m-row justify="end">
          <m-rounded-button @click="update">Save</m-rounded-button>
        </m-row>
      </m-container>

      <m-container
        v-show="false && !questionFormVisible"
        class="question-table"
      >
        <m-card>
          <m-card-text>
            <m-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>SN</th>
                    <th style="width: 70%">Question</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(question, i) in questions" :key="i">
                    <td>{{ i + 1 }}</td>
                    <td>
                      <quill-editor
                        :value="question.questionDetails.text"
                        :disabled="true"
                        :options="{
                          ...editorOptions,
                        }"
                      />
                    </td>
                    <td>
                      <div class="d-flex">
                        <m-button
                          color="orange"
                          outlined
                          small
                          @click="viewQuestion(i)"
                          ><m-icon>remove_red_eye</m-icon> View</m-button
                        >
                        <m-button
                          color="primary"
                          outlined
                          small
                          class="ml-2"
                          @click="editQuestion(i)"
                          ><m-icon>edit</m-icon> Edit</m-button
                        >
                        <m-button
                          color="red accent-3"
                          outlined
                          class="ml-2"
                          small
                          @click="deleteQuestion(i)"
                          ><m-icon>delete</m-icon> Delete</m-button
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3">
                      <div class="d-flex justify-end">
                        <m-button
                          color="primary"
                          outlined
                          @click="showQuestionForm"
                          ><m-icon>add</m-icon> Add Another</m-button
                        >
                        <m-button color="primary" depressed class="ml-3"
                          >Save</m-button
                        >
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </m-simple-table>
          </m-card-text>
        </m-card>
      </m-container>

      <question-create-form
        v-if="questionFormVisible"
        v-model="currentQuestion"
        :topics="topics"
        cancellable
        @save="addQuestion"
        @cancel="cancelQuestionAdd"
      ></question-create-form>
    </lazy-load>

    <v-dialog v-model="questionViewDialog" fullscreen>
      <m-card>
        <div style="position: absolute; top: 15px; right: 15px;">
          <m-icon @click="questionViewDialog = false">close</m-icon>
        </div>
        <m-card-text>
          <question-details
            :question="questionToView.questionDetails"
            :answers="questionToView.answers"
            :explanations="questionToView.explanations"
          ></question-details>
        </m-card-text>
      </m-card>
    </v-dialog> -->
    <group-question-form
      v-if="essay"
      v-model="essayData"
      :essay="essay"
      :questions="essay.questions"
      @saved="onSave"
      @cancel="onCancel"
    ></group-question-form>
    <m-row v-else class="mt-5" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular
    ></m-row>
  </m-container>
</template>

<script>
import { TopicsProvider, QuestionUpdater } from "#ecf/question-bank/mixins";
// import QuestionCreateForm from "#ecf/question-bank/components/SingleQuestion/Form";
// import QuestionDetails from "#ecf/question-bank/components/QuestionDetails";
import GroupQuestionForm from "#ecf/question-bank/components/GroupQuestion/Form";
// import ImageInput from "~ecf/components/ImageInput";
import essay from "#ecf/question-bank/api/essay";

export default {
  name: "GroupQuestionEditPage",
  components: {
    // ImageInput,
    // QuestionDetails,
    // QuestionCreateForm,
    GroupQuestionForm
  },
  inject: ["$courseOwnerId", "$courseId"],
  mixins: [TopicsProvider, QuestionUpdater],
  props: {
    essayId: {
      type: String
    }
  },
  data: () => ({
    essay: undefined,
    currentQuestion: {},
    questions: [],
    questionIds: [], // for remembering which questions are updated. rest question will be deleted
    questionFormVisible: false,
    editing: false,
    editingIndex: undefined,
    questionToView: {},
    questionViewDialog: false,
    editorOptions: {
      modules: {
        toolbar: false
      }
    },
    essayData: undefined
  }),
  /*computed: {
    essayLoaded() {
      return this.$store.state.questionBank.essays[this.essayId];
    },
  },*/
  async created() {
    await this.fetchEssay();
  },
  methods: {
    async onSave() {
      if (this.essayData) {
        try {
          let essayOnly = { ...this.essayData };
          delete essayOnly.questions;
          delete essayOnly.essay.questions;
          await essay.update(
            this.essayId,
            essayOnly.essay,
            this.$courseId(),
            this.$courseOwnerId()
          );
          for (let question of this.essayData.questions) {
            if (question.question_id) {
              // update those question content which are not deleted
              await this.update(question.question_id, {
                ...question
              });
              this.questionIds.splice(
                this.questionIds.indexOf(question.question_id),
                1
              );
            } else {
              // create questions which are newly added
              let tmpQuestion = { ...question };
              if (tmpQuestion.questionDetails.questionType === "desc") {
                delete tmpQuestion.answers;
              } else {
                delete tmpQuestion.answerPreference;
              }
              tmpQuestion.essay_id = this.essayId;
              tmpQuestion.questionDetails.topics = [
                ...this.essayData.essay.topics
              ];
              await this.$store.dispatch("questionBank/create", {
                question: tmpQuestion,
                courseId: this.$courseId(),
                courseOwnerId: this.$courseOwnerId()
              });
            }
          }
          // remove deleted question
          for (let _id of this.questionIds) {
            await this.$store.dispatch("questionBank/delete", { _id });
          }
          this.$router.go(-1);
        } catch (e) {
          this.$root.$emit("alert", [undefined, e.message]);
        }
      } else {
        this.$root.$emit("alert", ["Warning", "No change"]);
      }
    },
    onCancel() {
      this.$router.go(-1);
    },
    async fetchEssay() {
      const essay = await this.$store.dispatch("questionBank/getEssay", {
        essay_id: this.essayId,
        course_id: this.$courseId(),
        owner_id: this.$courseOwnerId(),
        include: ["questions"]
      });
      if (!("questions" in essay) || essay.questions.length == 0) {
        this.$root.$emit("alert", [
          undefined,
          "No questions in this Group Question, edit aborted"
        ]);
        this.$router.go(-1);
      }
      this.essay = {
        ...essay,
        image: { imageURL: null, file: null },
        topics: [...essay.questions[0].questionDetails.topics]
      };
      essay.questions.forEach(question => {
        this.questionIds.push(question.question_id);
      });
      if (essay.s3_loc) {
        this.essay.image = { imageURL: essay.s3_loc };
      }
    }
    /*async addQuestion() {
      if (this.editing) {
        this.questions.splice(this.editingIndex, 1, this.currentQuestion);
      } else {
        this.questions.push(this.currentQuestion);
      }
      this.currentQuestion = {};
      this.questionFormVisible = false;
    },
    showQuestionForm() {
      this.questionFormVisible = true;
    },
    cancelQuestionAdd() {
      this.questionFormVisible = false;
    },
    editQuestion(i) {
      this.editing = true;
      this.editingIndex = i;
      this.currentQuestion = this.questions[i];
      this.questionFormVisible = true;
    },
    deleteQuestion(i) {
      this.questions.splice(i, 1);
    },
    viewQuestion(i) {
      this.questionToView = this.questions[i];
      this.questionViewDialog = true;
    },
    async update() {
      await essay.update(this.essayId, this.essay);
    },*/
  }
};
</script>

<style></style>
